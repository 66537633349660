const api = window.location.hostname == 'localhost' ? 'http://localhost:3009/' : 'https://hanumaneditions.com/api/';

const getHeads = (method, body = null) => {
  let heads = {
    method: method,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
  }
  if (body) {
    heads.body = JSON.stringify(body);
  }
  return heads;
}

export const makeRequest = async (url, method, body) => {
  url = api + url;
  let heads = getHeads(method, body ? body : null);
  let response = await fetch(url, heads);
  let data = await response.json();
  return (data);
}

export const validateEmail = (email) => {
  var re = /\S+@\S+\.\S+/;
  return re.test(email);
}

export const updateCartState = (cart, item, quantity = 1) => {
  let newCart = { ...cart };
  let id = item.id;
  if (newCart[id]) {
    let newItem = { ...newCart[id] }
    newItem.count = newCart[id].count + quantity;
    newCart[id] = newItem;
  } else {
    newCart[id] = item;
    newCart[id].count = quantity;
  }
  return newCart;
}